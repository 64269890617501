import {inject, Injectable, NgZone} from '@angular/core';
import {environment} from "../../environments/environment";
import {StorageDataKey, StorageService} from "../service/storage.service";
import {BehaviorSubject, filter, from, map, Observable, Subject, switchMap, take, tap,} from "rxjs";
import {io, Socket} from "socket.io-client";
import {GetChatListParams, ListGetChatListParams} from "../model/messages/params/message.params";
import {MessageChatResponse} from "../model/messages/response/message-chat.response";
import {Router} from "@angular/router";
import {ApiService, JwtLoggedInModelData} from "./api.service";
import {UserRepositoryService} from "../repository/user-repository.service";
import {MessageErrorResponse, MessageSuccessResponse} from "../model/messages/response/messages-event.response";
import {OnMessageNotificationResponse} from "../model/messages/response/on-message-notification.response";
import {DeleteRequest, GetRequest, PatchRequest, PostRequest} from "./methods";
import {HttpClient, HttpParams} from "@angular/common/http";
import {EMessagesResponsesChat} from "desiren-core-lib/lib/enums/messages/responses.chat.messages.enum";
import {
  EMessagesMessageNotificationChat
} from "desiren-core-lib/lib/enums/messages/message-notification.chat.messages.enum";
import {EMessagesEventsChat} from "desiren-core-lib/lib/enums/messages/events.chat.messages.enum";
import {
  IMessagesAudioDefaultMessageResponse
} from "desiren-core-lib/lib/types/messages/audio.default-message.response.messages.interface";
import {
  IMessagesDocumentDefaultMessageResponse
} from "desiren-core-lib/lib/types/messages/document.default-message.response.messages.interface";
import {
  IMessagesMultipartDefaultMessageResponse
} from "desiren-core-lib/lib/types/messages/multipart.default-message.response.messages.interface";
import {
  IMessagesPhotoDefaultMessageResponse
} from "desiren-core-lib/lib/types/messages/photo.default-message.response.messages.interface";
import {IMessagesTextMessageResponse} from "desiren-core-lib/lib/types/messages/message/text.message.interface";
import {
  IMessagesVideoDefaultMessageResponse
} from "desiren-core-lib/lib/types/messages/video.default-message.response.messages.interface";
import {
  IDelayMessagesMessagesVideoMessageResponse
} from "desiren-core-lib/lib/types/messages/delay-message/video.delay-message.interface";
import {
  IDelayMessagesVoiceMessageResponse
} from "desiren-core-lib/lib/types/messages/delay-message/voice.delay-message.interface";
import {
  IDelayMessagesMultipartMessageResponse
} from "desiren-core-lib/lib/types/messages/delay-message/multipart.delay-message.interface";
import {
  IDelayMessagesPhotoMessageResponse
} from "desiren-core-lib/lib/types/messages/delay-message/photo.delay-message.interface";
import {
  IDelayMessagesTextMessageResponse
} from "desiren-core-lib/lib/types/messages/delay-message/text.delay-message.interface";
import {
  IDelayMessagesAudioMessageResponse
} from "desiren-core-lib/lib/types/messages/delay-message/audio.delay-message.interface";
import {
  IDelayMessagesDocumentMessageResponse
} from "desiren-core-lib/lib/types/messages/delay-message/document.delay-message.interface";
import {
  IMessagesSearchUsersListRequest
} from "desiren-core-lib/lib/types/messages/users/search-users.messages.request.interface";
import {
  IMessagesGetUsersListRequest
} from "desiren-core-lib/lib/types/messages/users/get-users-list.messages.request.interface";
import {IMessagesUserShortBroadcast} from "desiren-core-lib/lib/types/messages/users/user-short-broadcast.interface";
import {
  IMessagesListUsersResponse
} from "desiren-core-lib/lib/types/messages/users/list.users.messages.response.interface";
import {
  IMessagesUsersListResponse
} from "desiren-core-lib/lib/types/messages/users/users.list.messages.response.interface";
import {
  IMessagesCreateUsersListRequest
} from "desiren-core-lib/lib/types/messages/users/create-users-list.messages.request.interface";
import {
  IMessagesUpdateUsersListRequest
} from "desiren-core-lib/lib/types/messages/users/update-users-list.messages.request.interface";
import {EFilterByListMessages} from "desiren-core-lib/lib/types/messages/filter-by-list.messages.enum";
import {UUID} from "crypto";

export type MessageNotificationChatEvent = { type: EMessagesMessageNotificationChat, data: any };
export type DefaultMessageResponse = IMessagesAudioDefaultMessageResponse
  | IMessagesDocumentDefaultMessageResponse
  | IMessagesMultipartDefaultMessageResponse
  | IMessagesPhotoDefaultMessageResponse
  | IMessagesTextMessageResponse
  | IMessagesVideoDefaultMessageResponse
  | IDelayMessagesMessagesVideoMessageResponse
  | IDelayMessagesVoiceMessageResponse
  | IDelayMessagesMultipartMessageResponse
  | IDelayMessagesPhotoMessageResponse
  | IDelayMessagesTextMessageResponse
  | IDelayMessagesAudioMessageResponse
  | IDelayMessagesDocumentMessageResponse;

@Injectable({
  providedIn: 'root'
})
export class MessagesApiService {
  public getListParams = new GetChatListParams({
    page: 1, limit: 16, filterBy: {
      type: EFilterByListMessages.ALL,
    }
  });
  private socket: Socket | null = null;
  private isSocketConnected = new BehaviorSubject<boolean>(false);
  private messageNotificationSubj = new Subject<MessageNotificationChatEvent>();
  private notificationSubj = new Subject<any>();
  public chatListSubj = new BehaviorSubject<Map<String, MessageChatResponse>>(new Map<String, MessageChatResponse>());
  messageNotifications = this.messageNotificationSubj.asObservable();
  chatList$ = this.chatListSubj.asObservable();
  notifications = this.notificationSubj.asObservable();

  get socketId(): string {
    return this.socket?.id ?? '';
  }

  get unreadMessagesCount() {
    if (this.chatListSubj.value.size > 0) {
      return Array.from(this.chatListSubj.value.values()).map(c => c.unreadByMe).reduce((a, b) => {
        return a + b;
      });
    } else {
      return 0;
    }
  }

  constructor(private readonly storage: StorageService,
              private readonly api: ApiService,
              private readonly http: HttpClient,
              private router: Router,
              private zone: NgZone,
              private userRepo: UserRepositoryService) {
  }

  logout() {
    this.disconnect();
    this.socket = null;
    this.isSocketConnected.next(false);
    this.chatListSubj.next(new Map<String, MessageChatResponse>());
  }

  disconnect(): void {
    this.socket?.disconnect();
  }

  onConnect(): void {
    if (this.isSocketConnected.value) {
      this.socket?.disconnect();
    }
    this.socket = io(environment.wsUrl, {
      transports: ['websocket'],
      auth: {
        token: `Bearer ${this.storage.getLocalData(StorageDataKey.jwtToken)}`
      },
      path: '/ws',
      autoConnect: true,
      reconnection: true,
      secure: true,
    });
    this.onEvent(EMessagesResponsesChat.CONNECTED_RESPONSE)
      .pipe(
        take(1),
        tap((res: MessageSuccessResponse | MessageErrorResponse) => {
          if (res.response.statusCode === 200) {
            console.log('SOCKET CONNECTED');
            this.isSocketConnected.next(true);
            this.subOnMessageErrorResponse();
            this.subOnMessageNotification();
            this.loadAllChats();
          }
        })
      )
      .subscribe();
  }

  onEvent<D = any>(event: EMessagesResponsesChat): Observable<D> {
    const subj = new Subject<D>();
    this.socket!.on(event, (message) => {
      subj.next(message);
    });
    return subj.asObservable();
  }

  emit<R = any>(socketEvent: EMessagesEventsChat, params: any): Observable<R> {
    return this.isSocketConnected.pipe(
      filter((isConnected) => isConnected),
      take(1),
      switchMap(() => {
        console.log(socketEvent);
        console.log(params);
        return from(this.socket!.emitWithAck(socketEvent, params)).pipe(
          map((resp: MessageSuccessResponse<R> | MessageErrorResponse) => {
            console.log(resp);
            if (resp.response.statusCode !== 200) {
              const event = resp as MessageErrorResponse;
              throw new Error(`On ${socketEvent} event ${event.response.message.toString()}`);
            }
            const event = resp as MessageSuccessResponse;
            return event.response.data;
          })
        );
      })
    );
  }

  subOnMessageErrorResponse() {
    this
      .onEvent(EMessagesResponsesChat.MESSAGE_ERROR_RESPONSE)
      .pipe(tap((res: any) => {
        console.error(res);
        if (res.statusCode == 401) {
          try {
            this.api.refreshToken({
              userId: this.userRepo.meLazy().cache.id,
              jwtToken: this.storage.getLocalData(StorageDataKey.jwtToken),
              fcmToken: this.storage.getLocalData(StorageDataKey.fcmToken),
              browserId: this.storage.getLocalData(StorageDataKey.browserId) ?? '',
            }).then(response => {
              this.storage.setLocalData(StorageDataKey.jwtToken, response.jwtToken);
              this.onConnect();
            });
          } catch (e) {
            inject(Router).navigate(['/signin']);
          }
        }
      }))
      .subscribe();
  }

  subOnMessageNotification() {
    this
      .onEvent(EMessagesResponsesChat.MESSAGE_NOTIFICATION)
      .pipe(
        tap((res: OnMessageNotificationResponse) => {
          const type = res.type;
          this.messageNotificationSubj.next(res);
          switch (type) {
            case EMessagesMessageNotificationChat.NEW_CHAT:
              this.loadAllChats();
              break;
            case EMessagesMessageNotificationChat.CHAT_UPDATED:
              this.updateChat(res.data);
              break;
            default:
              break;
          }
        })
      )
      .subscribe();

    this
      .onEvent(EMessagesResponsesChat.KYC_NOTIFICATION)
      .pipe(
        tap((res: OnMessageNotificationResponse) => {
          const type = res.type;
          this.messageNotificationSubj.next(res);
          switch (type) {
            case EMessagesMessageNotificationChat.KYC_APPROVED:
              this.kycVerifiedCallback();
              break;
          }
        })
      )
      .subscribe();
    this
      .onEvent(EMessagesResponsesChat.NOTIFICATION)
      .pipe(
        tap((res: OnMessageNotificationResponse) => {
          this.notificationSubj.next(res.data);
        })
      ).subscribe();
  }

  updateChat(chat: MessageChatResponse) {
    let map = this.chatListSubj.value;
    if (map.has(chat.id)) {
      map.set(chat.id, chat);
    }
    this.chatListSubj.next(map);
  }

  filterChat(data: {type: EFilterByListMessages, listId?: UUID}) {
    this.getListParams.page = 1;
    this.getListParams.filterBy.type = data.type;
    if (data?.listId && data.type === EFilterByListMessages.LIST) {
      (this.getListParams.filterBy as ListGetChatListParams).listId = data?.listId;
    }
    this.chatListSubj.next(new Map<String, MessageChatResponse>());
    this.loadAllChats()
  }

  loadAllChats() {
    this.emit(EMessagesEventsChat.LIST_CHATS, this.getListParams).subscribe(res => {
      let map = this.chatListSubj.value;
      (res.data as MessageChatResponse[]).forEach(chat => {
        map.set(chat.id, chat);
      });
      this.chatListSubj.next(map);
      this.getListParams.page = this.getListParams.page + 1;
    });
  }

  async kycVerifiedCallback() {
    await this.userRepo.completeOnboarding();
    this.api.refreshToken({
      userId: this.userRepo.meLazy().cache.id,
      jwtToken: this.storage.getLocalData(StorageDataKey.jwtToken),
      fcmToken: this.storage.getLocalData(StorageDataKey.fcmToken),
      browserId: this.storage.getLocalData(StorageDataKey.browserId) ?? '',
    }).then(data => this._refreshUser(data), error => {
      this.zone.run(() => {
        if (!(this.router.url.includes('signup') || this.router.url.includes('signin'))) {
          this.router.navigate(['/signup'], {
            queryParams: {
              returnUrl: this.router.url
            }
          });
        }
      });
    });
  }

  async _refreshUser(data: JwtLoggedInModelData) {
    this.storage.setLocalData(StorageDataKey.jwtToken, data.jwtToken);
    this.userRepo.me().then(user => {
      this.storage.setLocalJsonData(StorageDataKey.apiUser, user.toJson());
      this.onConnect();
      this.zone.run(() => {
        this.router.navigate(['/home']);
      });
    });
  }

  async getDefaultMessage(): Promise<DefaultMessageResponse> {
    const request = new GetRequest(this.http, `${environment.messagesUrl}/default-message`);
    return await request.getResponse<DefaultMessageResponse>();
  }

  async setDefaultMessage(message: { [k: string]: any }): Promise<any> {
    console.log(message);
    const request = new PatchRequest(this.http, `${environment.messagesUrl}/default-message`, {
      message: message
    });
    return await request.getResponse();
  }

  async searchUsers(data: IMessagesSearchUsersListRequest): Promise<IMessagesUserShortBroadcast[]> {
    const request = new PostRequest(this.http, `${environment.messagesUrl}/broadcast/search`, data);
    let response = await request.getResponse();
    return response['data'];
  }

  async getLists(data: IMessagesGetUsersListRequest): Promise<IMessagesListUsersResponse> {
    const request = new PostRequest(this.http, `${environment.messagesUrl}/broadcast/list`, data);
    return await request.getResponse();
  }

  async getListById(data: {
    query?: string,
    listId?: string | null,
    page: number,
    limit: number,
    order: 'ASC' | 'DESC'
  }): Promise<IMessagesUsersListResponse> {
    const request = new GetRequest(this.http, `${environment.messagesUrl}/broadcast`, {}, true, true, new HttpParams({fromObject: data,},),);
    return await request.getResponse();

  }

  async createList(data: IMessagesCreateUsersListRequest): Promise<IMessagesUsersListResponse> {
    const request = new PostRequest(this.http, `${environment.messagesUrl}/broadcast`, data);
    return await request.getResponse();
  }

  async updateList(data: IMessagesUpdateUsersListRequest): Promise<IMessagesUsersListResponse> {
    const request = new PatchRequest(this.http, `${environment.messagesUrl}/broadcast`, data);
    return await request.getResponse();
  }

  async deleteList(id: string): Promise<any> {
    const request = new DeleteRequest(this.http, `${environment.messagesUrl}/broadcast/${id}`);
    return await request.getResponse();
  }
}
